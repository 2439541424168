import UserResource from 'common/services/resources/user.js'
import Captcha from 'common/components/captcha/captcha.vue'
import { required, email} from 'vuelidate/lib/validators'
export default {
	components: {Captcha},
	props: ['region'],
	data:
		function () {
			return {
				CONSTANTS: 
					{
						SENDMAIL_SUCCESS: this.$gettext('The email has been successfully sent'),
						ACCOUNT_NOT_EXIST : this.$gettext('This account does not exist'),
						SENDMAIL_ERROR: this.$gettext('Unable to send the email'),
						INFO_IMG: require('@/assets/images/picto_info.png'),
						CAPTCHA_ERROR : this.$gettext('Invalid captcha!')
					},
				email: '',
				loading:false
			}
		},
	validations: {
		email: {
			required,
			email
		}
	},
    created: function () {
    },			
	methods: {
		sendMailForNewPassword: function(){
			var _this = this;
			var captchaComponent = this.$refs['forgotPwdCaptcha'];
			if(captchaComponent.$v.$invalid)
			{
				this.eventHub.$emit('displayAlert',this.CONSTANTS.CAPTCHA_ERROR);
				return false;
			}	
			if(this.$v.email.$invalid)
			{
				this.eventHub.$emit('displayAlert',this.CONSTANTS.SENDMAIL_ERROR);
				return false;
			}	
			if(this.loading==true)return false;
			this.loading = true;		
			var captcha = {
				id : captchaComponent.captchaId,
				code: captchaComponent.captchaCode
			};
			UserResource.sendMailForNewPassword(this.email,captcha,this.region).then(
				function(response) {
					_this.loading = false;	
					captchaComponent.captchaReload(true);
					_this.email = '';
					_this.$v.$reset();
					_this.eventHub.$emit('forgotPasswordValidate');
					_this.eventHub.$emit('displayAlert',  _this.CONSTANTS.SENDMAIL_SUCCESS, 'success');
				}).catch(
				function(error) { 
					_this.loading = false;
					var status = (error.response ? error.response.status : -1);
					switch(status)
					{
						case 401:
								var msg = _this.CONSTANTS.CAPTCHA_ERROR;
								break;
						case 404:
								var msg = _this.CONSTANTS.ACCOUNT_NOT_EXIST;
								break;
						default:
								var msg = _this.CONSTANTS.SENDMAIL_ERROR;
								break;
					}			
					captchaComponent.captchaReload(true);
					_this.eventHub.$emit('displayAlert',  msg);	
			});
		},
	}
}
