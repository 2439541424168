import CaptchaResource from 'common/services/resources/captcha.js'
import Global from 'common/services/resources/global.js'
import { required, minLength} from 'vuelidate/lib/validators'
export default {  
    props:['position','region'],
    data: 
        function () {
                    return {
                                CONSTANTS: 
                                {
                                },
                                captchaId : '',
                                captchaCode : '',
                                captchaImg:''
                    }
                },  
    validations: {
        captchaCode: {
            required,
            minLength: minLength(5)
        }
    }, 
    watch: {
			captchaCode: function (val) {
                this.captchaCode = val.toUpperCase();
			}
  	}, 
    created: function () {
        this.captchaReload();
    },                 
    methods: 
        {
            init : function()
            {
                this.$v.$reset();
            },         
            captchaReload : function(bInit,region)
            {
                if(region==undefined)
                {
                    region = this.region;
                }
                this.captchaId = this.uuidV4();
                this.captchaCode='';
                this.captchaImg = region.endpoint+'/v1.0/'+'captcha/?id='+this.captchaId;  
                if(bInit)
                    this.init();
            },
            inputCaptcha : function()
            {
                this.$v.captchaCode.$touch()
                this.eventHub.$emit('inputCaptcha', {code: this.captchaCode, id: this.captchaId});
            } 
        }
}
